import { HStack, Switch } from "@chakra-ui/react";
import React, { useState, ChangeEvent } from "react";

function AdultCheckBox() {
  const [checked, setChecked] = useState(false);

  const handleCheckedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      alert("You will enter an 18+ chat room. Explicit nudity is not allowed on veez.cam.");
    }
  };

  return (
    <HStack outline={"1px solid black"} borderRadius={"5px"} padding={2} >
      <label>18+</label>
      <Switch colorScheme="red" isChecked={checked} onChange={handleCheckedChange} />
    </HStack>
  );
}

export default AdultCheckBox;
