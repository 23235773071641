import { Box } from "@chakra-ui/react";


function NewsComponent() {
  return (
    <Box outline={"2px solid black"} padding={2} borderRadius={5}>
        <p>News Component</p>
    </Box>
  );
}

export default NewsComponent;
