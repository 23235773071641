import React from 'react'
import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react'

function PasswordInput({ value, onChange }: { value: string, onChange: (value: string) => void }) {
    const [show, setShow] = React.useState(false);

    const handleClick = () => setShow(!show);
    const handleChange = (event: { target: { value: string; }; }) => {
        onChange(event.target.value);
    };

    return (
        <InputGroup size='md'>
            <Input
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                placeholder='Password'
                value={value}
                onChange={handleChange}
            />
            <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleClick}>
                    {show ? 'Hide' : 'Show'}
                </Button>
            </InputRightElement>
        </InputGroup>
    );
}

export default PasswordInput
