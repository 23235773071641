import { HStack } from "@chakra-ui/react";

function Footer() {
    return (
        <HStack padding={2} spacing={5}>
            <a href="/terms">Terms of Service</a>
            <a href="/privacy">Privacy Policy</a>
            <a href="/contact">Contact</a>
            <p> © 2024 veez.cam</p>
        </HStack>
    );
}

export default Footer;
