import { Grid, GridItem, HStack } from '@chakra-ui/react';
import { LoginComponent, ChatTypeSelector, Footer, NewsComponent } from '../components';

import bannerLogo from "../assets/veez_banner.svg";

function HomePage() {
    // set the title of the page
    document.title = "veez.cam - Home";
    return (
        <Grid templateRows={"auto 1fr auto"} height={"100vh"}>
            <GridItem>
                <HStack justifyContent={"center"} alignItems={"center"} height={"100%"} padding={5}>
                    <img src={bannerLogo} alt="veez.cam" />
                </HStack>
            </GridItem>
            <GridItem>
                <HStack justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <LoginComponent />
                    <ChatTypeSelector />
                    <NewsComponent />
                </HStack>
            </GridItem>
            <GridItem>
                <Footer />
            </GridItem>
        </Grid>
    );
}

export default HomePage;
