import DropdownLanguageSelector from "./DropdownLanguageSelector"
import AdultCheckBox from "./AdultCheckBox"
import { Box, Button, HStack, VStack } from "@chakra-ui/react"

const handleVideoChat = () => {
    console.log("Video Chat")
}

const handleTextChat = () => {
    console.log("Text Chat")
}

function ChatTypeSelector() {
    const loading = false
    return (
        <Box outline={"2px solid black"} borderRadius={2}>
            <VStack padding={2} margin={2}>
                <Button onClick={handleVideoChat} isLoading={loading} width={"100%"}>
                    Video Chat
                </Button>
                <Button onClick={handleTextChat} isLoading={loading} width={"100%"}>
                    Text Chat
                </Button>
            </VStack>
            <HStack spacing={4} padding={2}>
                <AdultCheckBox />
                <DropdownLanguageSelector />
            </HStack>
        </Box>
    )
}

export default ChatTypeSelector;
