import { Select } from '@chakra-ui/react';
import React, { useState } from 'react';


function DropdownLanguageSelector() {
    const [selectedLanguage, setSelectedLanguage] = useState('en');

    return (
        <Select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            <option value="de">German</option>
        </Select>
    )
}

export default DropdownLanguageSelector;
