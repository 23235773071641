import React from "react";
import { Box, VStack } from "@chakra-ui/react";

import bannerLogo from "../assets/veez_banner.svg";

function Rules() {
    document.title = "veez.cam - Rules";
    return (
        <VStack padding={4} spacing={10} justifyContent={"center"} alignItems={"center"}>
            <img src={bannerLogo} alt="veez.cam" />
            <Box outline="2px solid black" padding={4} borderRadius={2} w={'50%'}>
                <h1>Rules</h1>
                <li>Don't be a jerk</li>
                <li>Don't spam</li>
                <li>Don't share personal information</li>
            </Box>
        </VStack>
    );
}

export default Rules;
