import AdultCheckBox from "./AdultCheckBox";
import ChatTypeSelector from "./ChatTypeSelector";
import DropdownLanguageSelector from "./DropdownLanguageSelector";
import Footer from "./Footer";
import LoginComponent from "./LoginComponent";
import NewsComponent from "./NewsComponent";
import PasswordInput from "./PasswordInput";
import RegisterComponent from "./RegisterComponent";

export {
    AdultCheckBox,
    ChatTypeSelector,
    DropdownLanguageSelector,
    Footer,
    LoginComponent,
    NewsComponent,
    PasswordInput,
    RegisterComponent
};
