import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import './App.css';
import HomePage from './pages/Home';
import Register from './pages/Register';
import Rules from './pages/Rules';

function App() {
  return (
  <ChakraProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/rules" element={<Rules />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
  );
}

export default App;
