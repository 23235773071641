import React from "react";
import { Grid, GridItem, HStack } from "@chakra-ui/react";
import { Footer, RegisterComponent } from '../components';

import bannerLogo from "../assets/veez_banner.svg";


function Register() {
  document.title = "veez.cam - Register";
  return (
        <Grid templateRows={"auto 1fr auto"} height={"100vh"}>
            <GridItem>
                <HStack justifyContent={"center"} alignItems={"center"} height={"100%"} padding={5}>
                    <img src={bannerLogo} alt="veez.cam" />
                </HStack>
            </GridItem>
            <GridItem>
                <HStack justifyContent={"center"} alignItems={"center"} height={"100%"}>
                    <RegisterComponent />
                </HStack>
            </GridItem>
            <GridItem>
                <Footer />
            </GridItem>
        </Grid>
    );
}

export default Register;
