import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Input, VStack } from '@chakra-ui/react';
import PasswordInput from './PasswordInput';

function LoginComponent() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token: string) => {
    try {
      const response = await fetch('https://api.veez.cam/verify', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem('username', data.username);
        setIsLoggedIn(true);
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
      }
    } catch (error) {
      console.error('An error occurred during token verification:', error);
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await fetch('https://api.veez.cam/user/login', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
      });

      if (response.status === 200) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', data.username);
        setIsLoggedIn(true);
        alert('Login successful');
      } else if (response.status === 401) {
        setError('Invalid credentials');
      } else if (response.status === 403) {
        setError('You\'re banned :/');
      } else {
        setError('An error occurred');
        alert('Login failed, response status: ' + response.status);
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
      setError('An error occurred');
    }

    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  const handleSignUp = () => {
    navigate('/register');
  };

  if (isLoggedIn) {
    return (
      <Box outline="2px solid black" borderRadius={2}>
        <VStack spacing={4} padding={2}>
          <p>Welcome back, {localStorage.getItem('username')}</p>
          <Button onClick={handleLogout}>Logout</Button>
        </VStack>
      </Box>
    );
  }

  return (
    <Box outline="2px solid black" borderRadius={2}>
      <VStack spacing={4} padding={2}>
        <Input
          type="username"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <PasswordInput value={password} onChange={setPassword} />
        <Button onClick={handleLogin} isLoading={loading}>
          Login
        </Button>
        <p>
          Don't have an account?{' '}
          <Button variant="link" onClick={handleSignUp}>
            Sign up here
          </Button>
        </p>
        {error && <p>{error}</p>}
      </VStack>
    </Box>
  );
}

export default LoginComponent;
