import React from "react";
import { Box, Button, Checkbox, Input, VStack } from "@chakra-ui/react";
import PasswordInput from "./PasswordInput";
import { useNavigate } from "react-router-dom";

function RegisterComponent() {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [isRegistered, setIsRegistered] = React.useState(false);

    var ipAddr: string = ''
    fetch('https://ipinfo.io/ip').then(response => response.text()).then(data => ipAddr = data)

    const navigate = useNavigate();

    const handleRegister = async () => {
        setLoading(true);
        setError("");

        try {
            const response = await fetch("https://api.veez.cam/user/new", {
                method: "POST",
                body: JSON.stringify({ "username":username, "password":password, "ip":ipAddr }),
            });
            if (response.ok) {
                setIsRegistered(true);
                alert("Registration successful, welcome to veez.cam!");
                navigate("/home");
            } else {
                setError("Registration failed");
            }
        } catch (error) {
            console.error("An error occurred during registration:", error);
            setError("An error occurred during registration");
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box outline="2px solid black" borderRadius={2}>
            <VStack spacing={4} padding={2}>
                <Input
                    type="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <PasswordInput value={password} onChange={setPassword} />
                <Checkbox>I have read and I accept <Button variant={'link'} onClick={() => {navigate('/rules')}}>the rules</Button></Checkbox>
                <Button onClick={handleRegister} isLoading={loading}>
                  Register
                </Button>
                <p>
                    Already have an account?{' '}
                    <Button variant="link" onClick={() => navigate('/home')}>
                        Sign in on the home page
                    </Button>
                </p>
                {error && <p>{error}</p>}
            </VStack>
        </Box>
    );
}

export default RegisterComponent;
